.TextWithLabel__Label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    text-align: left;
}

.TextWithLabel__Text {
    display: block;
    font-size: 18px;
}

.TextWithLabel__Text > * {
    margin-bottom: 0;
}