.ToonPageAuction {
    margin: 20px auto 50px;
}

.ToonPageAuction__ChartContainer {
    margin-bottom: 10px;
}

.ToonPageAuction__Col {
    margin-bottom: 20px;
}
