.ToonPageHeader {
    border-bottom: 1px solid #ddd;
}

.ToonPageHeader__Image {
    width: 100%;
    height: 400px;
    background: no-repeat center;
    background-size: contain;
}

.ToonPageHeader__Image--placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ToonPageHeader__ForSaleBadge {
    position: absolute;
    top: 0;
    left: 20px;
}

.ToonPageHeader__MyToonBadge {
    position: absolute;
    width: 120px;
    top: 0;
    right: 20px;
}

.ToonPageHeader__Name {
    margin: 0;
}


@media all and (max-width: 768px) {
    .ToonPageHeader__Name {
        margin-bottom: 20px;
    }

    .ToonPageHeader__Info > * {
        width: 100%;
        margin-bottom: 10px;
    }

    .ToonPageHeader__MyToonBadge {
        width: 100px;
        right: 20px;
    }
}