.AccountStatus {
    position: fixed;
    z-index: 11;
    bottom: 0;
    left: 20px;
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 12px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #fff;
    font-family: 'Montserrat', sans-serif;
}

.AccountStatus__info {
    font-size: 12px;
    color: #9e9e9e;
}

.AccountStatus__address {
    font-size: 18px;
    font-weight: bold;
}

.AccountStatus__address a {
    color: #222;
}

.AccountStatus__address a:hover {
    text-decoration: underline;
}

.AccountStatus__InstallMetaMaskInfo {
    width: 300px;
    max-width: 75vw;
    display: block;
}

@media all and (max-width: 768px) {
    .AccountStatus {
        display: none;
    }
}