* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Catamaran', sans-serif;
    height: auto;
}

.AppContent {
    width: 100%;
    min-height: calc(100vh - 150px);
    overflow: hidden;
}

.containerWrapper {
    padding: 50px 0;
}

.containerWrapper--gray {
    background: #fafafa;
}

.container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
}

.container--small {
    max-width: 600px;
}

.container--large-text {
    font-size: 18px;
    line-height: 1.6em;
}

.relative {
    position: relative;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}

.color-primary {
    color: #ffcc00;
}

.color-secondary {
    color: #0b8aa8;
}

.color-lgray {
    color: #777;
}

/**
*** ANT OVERRIDES
 */

body .ant-modal .ant-modal-title,
body .ant-confirm-body .ant-confirm-title,
body .ant-notification-notice-message,
body .ant-message {
    font-weight: bold;
}
