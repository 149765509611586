.FamilyCard {
    width: 100%;
    min-height: 100px;
    margin-bottom: 20px;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.1);
    transition: all ease-in-out 0.2s;
    position: relative;
}

.FamilyCard:not(.FamilyCard--disabled):hover {
    box-shadow: 0 6px 30px 0 rgba(0,0,0,0.25);
}

.FamilyCard__image {
    width: 100%;
}

.FamilyCard__ComingSoon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}