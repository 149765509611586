.ToonsOwned__FamiliesContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.FamilyFilterItem {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.FamilyFilterItem:hover {
    text-decoration: underline;
}

.FamilyFilterItem--inactive {
    color: #ccc;
}

.FamilyFilterItem:not(:last-of-type) {
    margin-right: 18px;
}

.FamilyFilterItem__Name {
    margin-left: 4px;
}

.FamilyFilterItem__Cross {
    margin-left: 8px;
}

.ToonsOwned__Pagination {
    margin-top: 20px;
}
