.ToonCard {
    padding: 20px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.1);
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}

.ToonCard:hover {
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.15);
}

.ToonCard__image {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: center no-repeat;
    background-size: contain;
    margin-bottom: 20px;
}

.ToonCard__image--placeholder {
    display: flex;
    justify-content: center;
}

.ToonCard__image--placeholder .anticon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px);
}

.ToonCard__name {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0;
    color: #333;
}

.ToonCard__name--Placeholder {
    height: 27px;
}

.ToonCard__ForSaleBadge {
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
}

.ToonCard__MyToonBadge {
    position: absolute;
    width: 80px;
    top: -20px;
    right: -10px;
}


@media all and (max-width: 768px) {
    .ToonCard__MyToonBadge {
        position: absolute;
        width: 80px;
        top: -10px;
        right: 0;
    }
}
